import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getSearchData } from "../Hooks/apiData";
import { useSearchResults } from '../Hooks/useSearchResults';
import { SearchFilters } from '../Components/SearchFilters';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import { MenuItem, Select } from '@mui/material';

export const SearchResults = ({ userData, onUserListDel, onUserListSave, events, onEventSave, onEventDel}) => {
    let {search} = useParams();
    const navigate = useNavigate()
    let {state} = useLocation();
    const [brokenSearch] = useState(state && state.type.includes(",") && state.type.split(","));
    const [details,setDetails] = useState(null);
    const [notFoundGames,setNotFoundGames] = useState();
    const [itemOffset, setItemOffset] = useState(sessionStorage.getItem('itemOffset') ? JSON.parse(sessionStorage.getItem('itemOffset')) : 0);
    const [listAmount, setListAmount] = useState(10);
    const [dataFilter,setDataFilter] = useState(sessionStorage.getItem('dataFilter') ? JSON.parse(sessionStorage.getItem('dataFilter')) : "");
    const [andOrCheck, setAndOrCheck] = useState(sessionStorage.getItem('andOrCheck') ? JSON.parse(sessionStorage.getItem('andOrCheck')) : 1);
    const [currentFilters,setCurrentFilters] = useState(sessionStorage.getItem('filters') ? JSON.parse(sessionStorage.getItem('filters')) : []);
    const [releaseTime,setReleaseTime] = useState(sessionStorage.getItem('releaseTime') ? JSON.parse(sessionStorage.getItem('releaseTime')) : -2);
    const [paginateState, setPaginateState] = useState(sessionStorage.getItem('pageNum') ? JSON.parse(sessionStorage.getItem('pageNum')) : 0)
    const [popscoreData,setPopscoreData] = useState(localStorage.getItem("popscore") ? JSON.parse(localStorage.getItem("popscore")) : null)
    if (isNaN(search)) {state = {type:"name"};} else if (brokenSearch) {state = {type:brokenSearch[0]};}
    const [searchArray,setSearchArray] = useState(sessionStorage.getItem('filtersearch') ? JSON.parse(sessionStorage.getItem('filtersearch'))[0].search === search ? JSON.parse(sessionStorage.getItem('filtersearch')) : [{search:search,searchName:search,type:state.type}] : [{search:search,searchName:search,type:state.type}])
    const {testRef} = state ? getSearchData(searchArray, Number(releaseTime), details, setDetails, andOrCheck) : [];
    const {currentPageDetails, pageCount, gameScreenshots, showGameCarousel} = useSearchResults(details,dataFilter,itemOffset,userData, onUserListDel, onUserListSave, events, onEventSave, onEventDel, listAmount);
    const handlePageClick = (event) => {
        setPaginateState(event.selected);
        const newOffset = (event.selected * listAmount) % details.length;
        setItemOffset(newOffset);
    };
    useEffect(()=>{
        setReleaseTime(-2)
        setCurrentFilters([])
    },[brokenSearch])
    useEffect(()=>{
        sessionStorage.setItem('dataFilter',JSON.stringify(dataFilter));
    },[dataFilter])
    useEffect(()=>{
        sessionStorage.setItem('andOrCheck',JSON.stringify(andOrCheck));
    },[andOrCheck])
    useEffect(()=>{
        sessionStorage.setItem('releaseTime',JSON.stringify(releaseTime));
    },[releaseTime])
    useEffect(()=>{
        sessionStorage.setItem('filters',JSON.stringify(currentFilters));
        sessionStorage.setItem('filtersearch',JSON.stringify(searchArray));
    },[currentFilters])
    useEffect(()=>{
        sessionStorage.setItem('pageNum',JSON.stringify(paginateState));
    },[paginateState])
    useEffect(()=>{
        sessionStorage.setItem('itemOffset',JSON.stringify(itemOffset));
    },[itemOffset])
    useEffect(()=>{
        if(!popscoreData || ((new Date().getTime() - popscoreData.updateTime) > 86400000)) {
            axios({
            url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/popularity_primitives",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
            },
                data: `fields calculated_at,created_at,game_id,popularity_type,updated_at,value; sort value desc; limit 500; where popularity_type=(1,2,3);`
            })
            .then(response => {
                let tempPopscoreData = {}
                axios({
                    url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/games",
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
                    },
                        data: `fields name,cover.*,first_release_date; limit 500; where id = (`+response.data.map((e) => e.game_id).join(",")+`);`
                    })
                    .then(response2 =>  {
                    let hypeData = []
                    response.data.map((e) => {
                        let valType = e.popularity_type === 1 ? "clickVal" : e.popularity_type === 2 ? "wantVal" : e.popularity_type === 3 && "playingVal";
                        let tempFound = hypeData.find((e2)=>e2.game.id === e.game_id);
                        if(tempFound) {
                        tempFound[valType] = e.value
                        } else {
                        let temp = {};
                        let foundGame = response2.data.find(e2=>e2.id === e.game_id);
                        if(foundGame) {
                            temp["popId"] = e.id
                            temp["game"] = foundGame
                            temp[valType] = e.value
                            temp["updateTime"] = (e.updated_at - e.updated_at%1000)*1000
                            hypeData.push(temp)
                        }
                        }
                    } )
                    tempPopscoreData["data"] = hypeData;
                    tempPopscoreData["updateTime"] = new Date().getTime();
                    setPopscoreData(tempPopscoreData);
                    })
                .catch(err => {
                    console.error(err);
                });
            })
            .catch(err => {
                console.error(err);
            });
        }
    },[])
    useEffect(()=>{
        localStorage.setItem('popscore',JSON.stringify(popscoreData));
    },[popscoreData])

    const getAllGamePassGames = () => {
        if(notFoundGames)
            setNotFoundGames();
        else {
            let storedGamePassGames = localStorage.getItem("gamePassData") ? JSON.parse(localStorage.getItem("gamePassData")).data.map((item)=>item.title) : [];
            let titleFormatting = [...new Set(storedGamePassGames.map((item)=>item.match(/^(.+?)(?: \(.+?\))?$/)[1].replace(/[^\x00-\x7F]/g, "").split(/( PC.*|Xbox.*|\(.*|for W.*|- [W,P,D,Sta,Re].*| Remas.*| Anniversary Ed.*| Special Ed.*| Defin.*| -? Game.*| Game of.*| Standard.*|: Final.*| Ultimate Ed.*|Windows.*|:$|\.$)/g)[0].trim()).map((item4 => `\"`+item4.split(/(:$|-$)/g)[0]+`\"`)))]
            axios({
                url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/games",
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
                },
                    data: `fields aggregated_rating,aggregated_rating_count,cover.*,first_release_date,game_modes.name,genres.name,name,player_perspectives.name,
                    platforms.name,screenshots.*,themes.name; limit `+titleFormatting.length+`; where name=(`+titleFormatting.join(",")+`);`
                })
                .then(response => {
                    setDetails(response.data.sort((a,b)=>{if((a["first_release_date"] ? a["first_release_date"] : 0) > (b["first_release_date"] ? b["first_release_date"] : 0)) return -1}))
                    setNotFoundGames(storedGamePassGames.slice().filter((item)=>response.data.findIndex((i2)=>item.replace(/[^\x00-\x7F]/g, "").includes(i2.name)) === -1).map((item)=>item.replaceAll('"',"")))
                })
                .catch(err => {
                    console.error(err);
            });
        }
    }

    const addToSearch = (e) => { setPaginateState(0); setItemOffset(0); setSearchArray([...searchArray,e]); setCurrentFilters([...currentFilters, e.searchName]); }
    const removeFromSearch = (e) => { setPaginateState(0); setItemOffset(0); setSearchArray(searchArray.filter(f => f.searchName !== e)); setCurrentFilters(currentFilters.filter(f => f !== e)); sessionStorage.setItem('filters',JSON.stringify(currentFilters)); }
    const colStyle = {display:"grid",gridTemplateColumns:"17% 55%",justifyContent:"center",columnGap:"25px",marginLeft:showGameCarousel ? window.innerHeight < 1200 ? "-12%" : "-3%" : "0",transition:"1s"}

    return(
        <> 
            <div className="standinBg" />
            {!testRef.current && <div style={{textAlign:"center",color:"white",fontSize:"60px"}}>Loading...</div>}
            {testRef.current &&
            <div>
                <div style={{display:"grid",gridTemplateColumns:"auto auto",justifyContent:"center",paddingTop:"5px",columnGap:"50px",color:"white",textShadow:"2px 2px 2px #a760e9"}}>
                    <div className='boxTitle'><u>Trending Upcoming Titles</u></div>
                    <div className='boxTitle'><u>Trending Titles</u></div>
                    <div style={{maxWidth:"40vw", overflow:"auto",height:"20vh"}}>
                        <div style={{display:"flex", gap:"10px",height:"100%"}}>
                            {popscoreData && popscoreData.data.filter((pop) => new Date(pop.game.first_release_date*1000) > new Date().getTime()).map((e, index) => 
                                <div key={index} style={{display:"grid",fontWeight:"600",minWidth:"8vw",borderRadius:"10px",backgroundImage:e.game.cover && `linear-gradient(to bottom, rgb(0, 0, 0,0) 50%, rgba(0,0,0,0.4), black),url(//images.igdb.com/igdb/image/upload/t_1080p/${e.game.cover.image_id}.jpg)`, backgroundRepeat:"no-repeat", backgroundSize:"100%", backgroundPosition:"center"}}>
                                    <a onClick={()=>navigate("/game-details/"+e.game.id)} style={{display:"grid", cursor:"pointer",position:"relative"}}>
                                        <span style={{position:"absolute",bottom:"0",textAlign:"center",wordWrap:"normal",width:"100%"}}>{e.game.name}</span>
                                    </a>
                            </div>
                            )}
                        </div>
                    </div>
                    <div style={{maxWidth:"40vw", overflow:"auto",height:"20vh"}}>
                        <div style={{display:"flex", gap:"10px",height:"100%"}}>
                            {popscoreData && popscoreData.data.filter((pop) => new Date(pop.game.first_release_date*1000) <= new Date().getTime() && pop.clickVal > 0 && pop.playingVal > 0).sort(function(a,b) {return (b.clickVal*0.5 + b.playingVal*0.3 + (b.wantVal ? b.wantVal*0.2 : 0)) - (a.clickVal*0.5 + a.playingVal*0.3 + (a.wantVal ? a.wantVal*0.2 : 0)) }).map((e, index) => 
                                <div key={index} style={{display:"grid",fontWeight:"600",minWidth:"8vw",borderRadius:"10px",backgroundImage:e.game.cover && `linear-gradient(to bottom, rgb(0, 0, 0,0) 50%, rgba(0,0,0,0.4), black),url(//images.igdb.com/igdb/image/upload/t_1080p/${e.game.cover.image_id}.jpg)`, backgroundRepeat:"no-repeat", backgroundSize:"100%", backgroundPosition:"center"}}>
                                    <a onClick={()=>navigate("/game-details/"+e.game.id)} style={{display:"grid", cursor:"pointer",position:"relative"}}>
                                        <span style={{position:"absolute",bottom:"0",textAlign:"center",wordWrap:"normal",width:"100%"}}>{e.game.name}</span>
                                    </a>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
                <div style={colStyle}>
                    <div style={{marginTop:"15%"}}>
                        <h6 style={{color:"white",textShadow:"1px 1px 10px #a760e9"}}><i>Note: Platform and Game Mode have Exclusive Filtering, select an additional respective filter to see normal filtering</i></h6>
                        <div className="sFilterName" style={{width:"45%",backgroundImage:releaseTime === -1 && "linear-gradient(270deg,rgba(100, 180, 250),rgb(71, 0, 137), rgba(0,0,0,1))"}} onClick={()=>(releaseTime === -2 || releaseTime > 0) ? setReleaseTime(-1) : setReleaseTime(-2)}>
                            Recently Released
                        </div>
                        <div className="sFilterName" style={{backgroundImage:releaseTime > 0 && "linear-gradient(270deg,rgba(100, 180, 250),rgb(71, 0, 137), rgba(0,0,0,1))"}} >Upcoming Releases Within
                            <Select value={releaseTime} onChange={(e)=>{ setPaginateState(0); setItemOffset(0); setReleaseTime(e.target.value);}} sx={{color: "white", '.MuiSvgIcon-root ': {fill: "white !important",}}} size="small">
                                {releaseTime == -2 ? <MenuItem value={-2}>--</MenuItem> : <MenuItem value={-1}>--</MenuItem>}
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                            </Select>
                        month(s)</div>
                        <div style={{marginTop:"5px"}}>
                            <div className="sFilterName" style={{backgroundImage:"linear-gradient(270deg, #e52a35, #0d1041, #0e7a0d)"}} onClick={getAllGamePassGames}>All Gamepass/EAPlay Games</div>
                            {notFoundGames && <div>
                                    <h5 style={{color:"white",textShadow:"1px 1px 10px #a760e9"}}>Games Potentially Not Found Due to Formatting Issues (Use Manual Search):</h5>
                                    <div style={{textAlign:"center",color:"white",textShadow:"1px 1px 10px #a760e9",display:"grid"}}>{notFoundGames?.map((item, index)=><div key={index} onClick={()=>{navigate("/search-results/"+encodeURIComponent(item), {state: {type:"name"}});window.location.reload(false);}}>{item},</div>)}</div>
                                </div>}
                        </div>
                        {currentFilters.length > 0 && !notFoundGames ? 
                                                <><div style={{display:"grid",rowGap:"10px"}}>
                                                    <div className="boxTitle" style={{fontSize:"24px"}}>Current Filters</div>
                                                    <div style={{width:"80px",height:"3px",backgroundColor:"white"}} />
                                                    <div style={{display:"flex",fontWeight:"600",flexWrap:"wrap",gap:"5px",overflowY:"auto",maxHeight:"160px"}}>
                                                        {currentFilters.map((e,index)=><div key={index} className="sFilterNameCurr" onClick={() => removeFromSearch(e)}>{e}</div>)}
                                                    </div>
                                                </div><br /></> : null}
                        {!notFoundGames &&
                            <SearchFilters
                                currentFilters={currentFilters}
                                addToSearch={(e) => addToSearch(e)}
                                removeFromSearch={(e) => removeFromSearch(e)}
                                andOrCheck={andOrCheck}
                                setAndOrCheck={(e) => setAndOrCheck(e)}
                            />}
                    </div>
                    <div style={{width:window.innerHeight > 1200 ? "35vw" : "42vw"}}>
                        <div style={{display:"grid",gridTemplateColumns:"auto 10%",marginBottom:"10px",marginTop:"10px"}}>
                            <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{width:"80%",margin:"auto"}}>
                                <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onChange={() => setDataFilter("aggregated_rating")} checked={dataFilter == "aggregated_rating"} />
                                <label className="btn btn-primary" htmlFor="btnradio1">Critic Rating</label>

                                <input type="radio" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" onChange={() => setDataFilter("hltbReview")} checked={dataFilter == "hltbReview"} />
                                <label className="btn btn-primary" htmlFor="btnradio5">User Rating</label>

                                <input type="radio" className="btn-check" name="btnradio" id="btnradio2" onChange={() => setDataFilter("name")} autoComplete="off" checked={dataFilter == "name"} />
                                <label className="btn btn-primary" htmlFor="btnradio2">Name</label>

                                <input type="radio" className="btn-check" name="btnradio" id="btnradio3" onChange={()=> setDataFilter("first_release_date")} autoComplete="off" checked={!dataFilter || dataFilter == "first_release_date"} />
                                <label className="btn btn-primary" htmlFor="btnradio3">Release Date</label>

                                <input type="radio" className="btn-check" name="btnradio" id="btnradio4" onClick={() => { setNotFoundGames(); setSearchArray([searchArray[0]]); setCurrentFilters([]); setDataFilter(""); setPaginateState(0); setItemOffset(0); setReleaseTime(-2); }} autoComplete="off" />
                                <label className="btn btn-danger" htmlFor="btnradio4">Clear Filters</label>
                            </div>
                            <Select value={listAmount} onChange={(e)=>{ setPaginateState(0); setItemOffset(0); setListAmount(e.target.value);}} sx={{justifySelf:"flex-end",width:"75px",backgroundColor:"#a760e9",color: "white", '.MuiSvgIcon-root ': {fill: "white !important",}}} size="small">
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                        </Select>
                        </div>
                        {search && search !== "all" && <div className='boxTitle' style={{textAlign:"center"}}>Current Search: {brokenSearch ? brokenSearch[1] : search} <button className='btn btn-danger' onClick={()=>{setNotFoundGames(); setSearchArray([searchArray[0]]); setCurrentFilters([]); setDataFilter(""); setPaginateState(0); setItemOffset(0); setReleaseTime(-2); navigate("/search-results/all");window.location.reload();}}>X</button></div>}
                        {currentPageDetails && (currentPageDetails.length > 0 ? 
                            <div>
                                <div style={{display:"grid",gap:"20px"}}>
                                    {currentPageDetails}
                                </div>
                                {(showGameCarousel && gameScreenshots) && <div className="gameDCaro" style={{position:"absolute",left:window.innerHeight > 1200 ? "64%" : "59%",top:"25%",scale:window.innerHeight > 1200 ? "0.9" : "0.7", animation:"fadeIn 1.5s"}}>
                                    <Carousel autoPlay interval={2000}>
                                        {gameScreenshots}
                                    </Carousel>
                                </div>}
                            </div>
                        : 
                            <div style={{textAlign:"center",color:"white",fontSize:"50px"}}>
                                No Results Found...
                                <ReactPaginate
                                    activeClassName={'item activePage '}
                                    breakClassName={'item '}
                                    containerClassName={'pagination'}
                                    disabledClassName={'disabled-page'}
                                    nextClassName={"item next "}
                                    pageClassName={'item pagination-page '}
                                    previousClassName={"item previous"}
                                    breakLabel="..."
                                    nextLabel={<i className="fa fa-arrow-circle-right" aria-hidden="true"></i>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel={<i className="fa fa-arrow-circle-left" aria-hidden="true"></i>}
                                    renderOnZeroPageCount={null}
                                    forcePage={paginateState}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div style={{position:"sticky",bottom:"20px",width:"40%",margin:"auto"}}>
                    <ReactPaginate
                        activeClassName={'item activePage '}
                        breakClassName={'item '}
                        containerClassName={'pagination'}
                        disabledClassName={'disabled-page'}
                        nextClassName={"item next "}
                        pageClassName={'item pagination-page '}
                        previousClassName={"item previous"}
                        breakLabel="..."
                        nextLabel={<i className="fa fa-arrow-circle-right" aria-hidden="true"></i>}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel={<i className="fa fa-arrow-circle-left" aria-hidden="true"></i>}
                        renderOnZeroPageCount={null}
                        forcePage={paginateState}
                    />
                </div>
            </div>
            }
        </>
    )
}

export default SearchResults;