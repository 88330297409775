import React, { useEffect, useState } from 'react';

export const Day = ({ day, onClick }) => {
  const className = `day ${day.value === 'padding' ? 'padding' : ''} ${day.isCurrentDay ? 'currentDay' : ''}`;
  const threeEvents = !day.event ? '' : day.event.slice(0,3).map((e, index) => <div key={index} className='event'>{e.title}</div>);
  const eventCount = day.event === null ? '' : day.event.length;
  const eventImages = day.event && day.event.filter(e => e.game != null).map(e => e.game.cover.image_id);
  const [currentImage,setCurrentImage] = useState(0);

  useEffect(() => {
    if(eventImages) {
        let thing = setInterval(() => {
          if(currentImage+1 >= eventImages.length)
            setCurrentImage(0);
          else
            setCurrentImage((currentImage) => currentImage + 1)
        }, 5000);

        return () => {
          clearInterval(thing);
        }
    }
  }, [eventImages]);

  return (
    <div onClick={onClick} className={className} style={eventImages && eventImages[currentImage] && {backgroundImage:`url(//images.igdb.com/igdb/image/upload/t_1080p/${eventImages[currentImage]}.jpg)`, backgroundRepeat:"no-repeat", backgroundSize:"100% 100%", transition:"1.5s"}}>
      {day.value === 'padding' ? '' : <p style={{textShadow:"1px 1px 1px black"}}>{day.value}</p>}
      {day.event && eventCount<4 && eventCount>0 && <div>{threeEvents}</div>}
      {day.event && eventCount>=4 && <div className='event'>{eventCount} Events</div>}
    </div>
  );
};

export default Day;